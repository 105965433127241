window.angular.module('MyHippoProducer.Components').directive('licenseErrorCard', function () {
    return {
        restrict: 'E',
        scope: {
            icon: '@',
            alt: '@',
            head: '@',
            body: '@',
        },
        templateUrl: 'components/license-error-card/license-error-card.html',
    };
});
